<template>
  <div class="presentations template-1">
    <header class="page-header container">
      <h1>{{ translations.tcAuxiliaryPlacementDates }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <data-table
        :fields="upcoming_fields"
        :items="upcoming_placements"
        :addItems="null"
        :title="translations.tcUpcomingAuxiliaryOpportunities"
        :toggle="true"
        :csvUrl="upcomingPlacementDatesAsCSVUrl"
        :pdfUrl="upcomingPlacementDatesAsPDFUrl"
        :reportName="reportName"
        :i18n="translations.components"
      ></data-table>
      <!-- / Data Tables -->
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableScripture.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'auxiliary-placement-dates',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      hiddenItems: {
        display: false,
      },
      upcoming_fields: [
        {
          key: 'placement_date',
          label: '',
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        { key: 'facility_name', label: '', sortable: true },
        { key: 'city', label: '', sortable: true },
      ],
      addPlacement: {
        display: true,
        text: '',
        action: 'addPlacementDate',
      },
      secured_placement_date_add_controls: {
        add_aux_facility_placement_date_button: 'c1ce0edd-3cea-49d2-9376-c71df402e8c8',
      },
      reportName: 'UpcomingAuxiliaryPlacementDates'
    }
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      loadUpcomingAuxiliaryPlacementDatesCSVUrl: 'scriptureDistribution/loadUpcomingAuxiliaryPlacementDatesCSVUrl',
      loadUpcomingAuxiliaryPlacementDatesPDFUrl: 'scriptureDistribution/loadUpcomingAuxiliaryPlacementDatesPDFUrl',
      loadAuxiliaryPlacementDatesByCampLast12: 'auxiliary/loadAuxiliaryPlacementDatesByCampLast12',
      loadChurchPresentationsReportUrls: 'churchMinistry/loadChurchPresentationsReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
    }),
    async page_load() {
      await Promise.all([
        await this.loadAuxiliaryPlacementDatesByCampLast12(),
        await this.loadChurchPresentationsReportUrls(),
        await this.loadUpcomingAuxiliaryPlacementDatesPDFUrl(),
        await this.loadUpcomingAuxiliaryPlacementDatesCSVUrl()
      ])
    },
    async addPlacementDate() {
      await this.setSelectedLocationKey('')
      await this.clearLocationDetails()
      await this.setSelectedPlacementDateKey('')
      await this.clearPlacementDateDetails('')
      this.$router.push('/programs/sd/add-placement-date')
    },
    loadTranslations() {
      this.upcoming_fields[0].label = this.translations.tcDate
      this.upcoming_fields[1].label = this.translations.tcName
      this.upcoming_fields[2].label = this.translations.tcCity
      this.addPlacement.text = this.translations.tcAddPlacement
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('camp-select', 'data-table-scripture', 'security-banner'),
        this.loadTranslations(),
        this.addPlacement.display = this.iCanSeeAny(this.secured_placement_date_add_controls)
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      // left in until we have all reports finished
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      upcoming_placements: 'auxiliary/upcoming_placements',
      upcomingPlacementDatesAsCSVUrl: 'scriptureDistribution/upcomingPlacementDatesAsCSVUrl',
      upcomingPlacementDatesAsPDFUrl: 'scriptureDistribution/upcomingPlacementDatesAsPDFUrl',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.presentations {
  .g-table {
    td,
    th {
      &:nth-of-type(1) {
        width: 200px;
      }
      &:nth-of-type(2) {
        width: 350px;
      }
      &:nth-of-type(3) {
        width: 125px;
      }
      &:nth-of-type(4) {
        width: 325px;
      }
      &:nth-of-type(5) {
        width: auto;
      }
    }
  }
}
</style>
